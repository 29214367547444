
import './App.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

function App() {

  const [checklist, setChecklist] = useState([]);  

  const handleCheckboxChange = async (changedItem) => {    

    const updatedItems = checklist.map(item => {  
      if (item.id === changedItem.id) {  
        return { ...item, isChecked: !item.isChecked };  
      }  
      return item;  
    });  
  
    setChecklist(updatedItems);  

    try {  

      const formData = {  
        id: changedItem.id,  
        itemName: changedItem.itemName,  
        isChecked: !changedItem.isChecked  
      };  
    
      const response = await axios.post(`https://api.shiol.com/xkg/UpdateCheckList`,formData);  
      setChecklist(response.data);  
    } catch (error) {  
      console.error("Updating error: ", error);  
    }  
  };  

  useEffect(() => {  
    const fetchData = async () => {  
      try { 
        const response = await axios.get('https://api.shiol.com/xkg/GetCheckList');  
        setChecklist(response.data);  
      } catch (error) {  
        console.error("Fetching error", error);  
      }  
    };  
  
    fetchData();  
  }, []); 
  
  return (  
    <div>  
      <h2>Shared Checklist</h2>  
      <form>  
        {checklist.map((item) => (  
          <div key={item.id}>  
            <label>  
              <input  
                type="checkbox"  
                name={item.itemName}  
                checked={item.isChecked}  
                onChange={() => handleCheckboxChange(item)}  
              />  
              {item.itemName}  
            </label>  
          </div>  
        ))}  
      </form>  
    </div>  
  );  
}  

export default App;